import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { NetworkManager } from "../../Components/NetworkManager";

import defaultImage from "../../images/defaultImage.png";

import { CommonUtil } from "../../utils/CommonUtil";

interface InstagramManageProps { }

const instagramProfiles = [{}];

const InstagramManage: React.FC<any> = ({
  fbPagesData,

  acctAndAccessData,
}: any) => {
  console.log(acctAndAccessData);
  const history = useNavigate();
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [instagramData, setInstagramData] = useState<any>();
  useEffect(() => {
    if (Object.keys(fbPagesData).length > 0) {
      let tempArray: any = [];

      const pages = fbPagesData.pages;

      pages?.length > 0 &&
        pages.map((pageData: any) => {
          tempArray.push({
            pageId: pageData.pageId,
            pageAccessToken: pageData.pageAccessToken,
          });
        });

      let data = {
        pages: tempArray,
        longAccessToken: acctAndAccessData.longAccessToken,
        accountId: acctAndAccessData.id,
      };

      NetworkManager.sendJsonRequest(
        "api/account/pages/instagramProfiles/",
        data,
        "",
        "POST",
        true,
        onSuccessCall,
        onErrorCall
      );
    }
  }, []);

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData) {
      setInstagramData(respData);
      setPagesCount(
        respData.instagramProfiles && respData.instagramProfiles?.length
      );
    }
  };

  const onErrorCall = (err: any) => { };

  const handleConnect = (id: any, instaId: any) => {
    if (id) {
      let connectData = {
        pageId: id,
        instaId: instaId,
      };

      NetworkManager.sendJsonRequest(
        "api/account/pages/instagramProfiles/connect",
        connectData,
        "",
        "POST",
        true,
        onConnectSuccessCall,
        onConnectErrorCall
      );
    }
  };

  const onConnectSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData.error) {
      history("/connectError");
    } else if (respData) {
      history("/connectSuccess");
    }
  };

  const onConnectErrorCall = (err: any) => {
    history("/connectError");
  };

  return (
    <>
      {/* <div className="row justify-content-center mb-4 manage"> */}
      {/* <div className="col-md-12 col-lg-8 col-xl-6"> */}
      <div className="text-center">
        <h3 className="fbtext">
          {instagramData ?
            <div>
              We found {pagesCount ? pagesCount : 0} Instagram Accounts managed by you.
            </div> :
            <div>
              Fetching Instagram Accounts.
            </div>
          }
        </h3>
      </div>{" "}
      {instagramData && instagramData?.instagramProfiles?.length > 0
        ? instagramData.instagramProfiles.map((item: any, index: any) => {
          const pagestatus =
            item?.instaWebhookSubscriptionStatus === "CONNECTED" &&
            item?.caucusConnectionStatus === "CONNECTED";
          return (
            <div className="card" key={index}>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="widgets_div d-flex align-items-center">
                    <div className="icon_div">
                      <span>
                        {item.instaProfilePic ? (
                          <img
                            src={item.instaProfilePic}
                            className="rounded-circle avatar-sm"
                            alt=""
                          />
                        ) : (
                          <img
                            src={defaultImage}
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                    </div>{" "}
                    <div className="av-div">{item.instaUserName}</div>
                  </div>{" "}
                  <div className="text-right">
                    {/* pageStatus */}{" "}
                    <button
                      type="button"
                      className={`btn rounded-pill btn-small btn-sm ${pagestatus ? "btn-secondary" : "btn-primary"
                        }`}
                      onClick={
                        !pagestatus
                          ? () => handleConnect(item.pageId, item.instaId)
                          : () => { }
                      }
                    >
                      {pagestatus ? "Connected" : "Connect"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
        : null}
      {/* </div>
      </div> */}
    </>
  );
};

export default InstagramManage;
