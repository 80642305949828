import { toast } from "react-toastify";
export class CommonUtil {
  static appId = "";

  static parseData(data: any): any {
    if (data) {
      var restData = JSON.parse(data);
      if (restData) {
        return restData;
      }
    }
    return null;
  }
}
export const showToast = (type: any, msg: any) => {
  switch (type) {
    case "SUCCESS":
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    case "ERROR":
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    case "WARNING":
      toast.warn(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    default:
      return false;
  }
};
