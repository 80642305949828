import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { extractNodesEdges } from "../chatbottool/publishTemplate";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);

const HistoryDataTable = ({ rows, setOptinTemplateList }: any) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const {
    saveNewNodeAndEdges,
    saveTemplates,
    saveTemplateName,
    savePageIdAndName,
    setSubscriber,
  } = useProductsContext();

  const navigate = useNavigate();
  const userTimezone = dayjs.tz.guess();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter rows based on status
  const filteredRows = rows.filter(
    (row: any) => row.optinTemplateStatus === "Sent"
  );

  const onOptinTemplateNameClick = (
    e: any,
    optinTemplateId: string,
    optinTemplateName: string,
    scheduledTime: Date,
    listOfPayloads: String
  ) => {
    let getOptinTemplateId = "optinTemplateId=" + optinTemplateId;
    saveTemplateName({
      optinTemplateId,
      optinTemplateName,
      scheduledTime,
      listOfPayloads,
    });
    console.log(
      optinTemplateId,
      optinTemplateName,
      scheduledTime,
      listOfPayloads
    );

    try {
      NetworkManager.sendJsonRequest(
        "getOptinTemplateData",
        "",
        getOptinTemplateId,
        "GET",
        true,
        onSuccessCall,
        onErrorCall
      );
    } catch (error) {}
  };

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData) {
      saveTemplates(respData.optinTemplateData);
      saveNewNodeAndEdges([], []);
      const result = extractNodesEdges(respData.optinTemplateData);
      const { nodes, edges } = result;

      saveNewNodeAndEdges(nodes, edges);
      setSubscriber("subscriber");
      navigate(`/dashboard/chatbot`, { state: { create: false } });
    }
  };
  const onErrorCall = (err: any) => {};

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "150px", p: 1 }}> Name </TableCell>
            <TableCell sx={{ width: "200px", p: 1 }}> Sent Time </TableCell>
            <TableCell sx={{ width: "200px", p: 1 }}> List Of Topics</TableCell>
            <TableCell sx={{ width: "80px" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any, index: any) => (
              <TableRow key={row.optinTemplateName}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "170px", cursor: "pointer" }}
                  onClick={(e: any) =>
                    onOptinTemplateNameClick(
                      e,
                      row.optinTemplateId,
                      row.optinTemplateName,
                      row.scheduledTime,
                      row.listOfPayloads
                    )
                  }
                >
                  {row.optinTemplateName}
                </TableCell>
                <TableCell sx={{ width: "200px" }}>
                  {dayjs
                    .utc(row.scheduledTime)
                    .tz(userTimezone)
                    .format("YYYY-MM-DD hh:mm:ss A")}
                  &nbsp;(
                  {userTimezone === "Asia/Calcutta"
                    ? "IST"
                    : userTimezone === "America/New_York"
                    ? "EST"
                    : ""}
                  )
                </TableCell>
                <TableCell sx={{ width: "200px", textTransform: "capitalize" }}>
                  {row.listOfPayloads.join(", ")}
                </TableCell>
                <TableCell sx={{ width: "80px" }}></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default HistoryDataTable;
