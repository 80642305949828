import React from 'react';
import { Header } from '../../Components/Header/Header';
interface ConnectErrorProps {
}

const ConnectError: React.FC<ConnectErrorProps> = () => {
    return (
        <div className="account-pages container-login100">
            <div className="container">
                <Header />
                <div className="row justify-content-center mb-4">
                    <div className="col-md-6 col-lg-6 col-xl-8">
                            <div className="card cau-title pointer">
                                <div className="card-body text-center">
                                    <div className="card-block">
                                        <div className="d-block">
                                            <img src="../assets/images/link-wrong.png" alt='' />
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12 col-xl-10">
                                                <h1 className="card-title mt-3 mb-0">Unfortunately, your page did not successfully connect to <span className="cau-color">Caucus!</span></h1>
                                                <div className="wrong-title">We’re working on fixing what’s wrong.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectError