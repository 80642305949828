import React from "react";
import { Header } from "../../Components/Header/Header";
interface ConnectThankyouProps {}

const ConnectThankyou: React.FC<ConnectThankyouProps> = () => {
  return (
    <div className="account-pages container-login100">
      <div className="container">
        <Header />
        <div className="row justify-content-center mb-4">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card cau-title">
              <div className="card-body text-center">
                <div className="card-block">
                  <div className="d-block">
                    <img src="../assets/images/link.png" alt="" />
                  </div>
                  <h1 className="card-title mt-3 mb-0">
                    Your page is now connected to{" "}
                    <span className="cau-color">Caucus!</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectThankyou;
