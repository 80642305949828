import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import { log } from "console";
import ToastManager from "../ToastManager";

const ConnectOrganicPost = ({ templateName, handleClose }: any) => {
  const { pageInfo } = useProductsContext();

  const [posts, setPosts] = useState([]);
  const [postPublish, setPostPublish] = useState<any>([]);
  useEffect(() => {
    let params: any = "";
    let pageId = pageInfo?.pageId;
    if (pageId) {
      params = `pageId=${pageId}`;
      try {
        NetworkManager.sendJsonRequest(
          "api/account/existingPagePosts",
          "",
          params,
          "GET",
          true,
          onSuccessCall,
          onErrorCall
        );
      } catch (error) {}
    }
  }, []);
  const onClickSelectPost = (e: any, post: any) => {
    const ischecked = e.target.checked;
    const newSelect = post.postId;
    if (ischecked) {
      setPostPublish((prevPost: any) => [...prevPost, newSelect]);
    } else {
      if (postPublish && postPublish.length > 0) {
        const tempArray = postPublish.filter(
          (item: any) => item !== post.postId
        );
        //
        setPostPublish(tempArray);
      }
    }
  };
  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      setPosts(respData);
    }
  };
  const onSuccessCall2 = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      ToastManager.showToast(
        "The selected posts are associated to the template.",
        "Success"
      );
      handleClose();
      //setPosts(respData);
    }
  };

  const onErrorCall = (err: any) => {
    ToastManager.showToast(
      "Error Occured while associating the posts to the template.",
      "Error"
    );
  };
  const onPostSpecificPublish = () => {
    let tempArray: any = [];
    const getPostid = () => {
      postPublish?.length > 0 &&
        postPublish.map((pB: any) => {
          tempArray.push({
            postId: pB,
          });
        });
      return tempArray;
    };
    const req = {
      templateName: templateName,
      pageId: pageInfo.pageId,
      // postIds: [{ ...postPublish }],
      postIds: getPostid(),
    };

    //  let params = `template_name=${templateName}&post_id=${post.postId}&page_id=${pageInfo.pageId}&fbAccountId=${pageInfo.fbAccountId}`;
    try {
      NetworkManager.sendJsonRequest(
        "tieMultiplePostIds",
        req,
        "",
        "POST",
        true,
        onSuccessCall2,
        onErrorCall
      );
    } catch (error) {}
  };
  {
    return (
      <>
        <div>
          {posts?.length > 0 &&
            posts.map((post: any, indx: any) => {
              const idPost = post.postId.split("_")[1];
              return (
                <div key={idPost} style={{ margin: "10px" }}>
                  <Card sx={{ maxWidth: 345, margin: "auto" }}>
                    <CardActionArea>
                      {post.postImage !== "" ? (
                        <CardMedia
                          component="img"
                          height="140"
                          image={post.postImage}
                          alt="post media"
                        />
                      ) : null}
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {post.postMessage}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {post.postMessage}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Typography>Select Post</Typography>
                      <Checkbox
                        aria-label="Hey"
                        name="checked"
                        onChange={(e) => onClickSelectPost(e, post)}
                      />
                      {/* <Button
                      size="small"
                      color="primary"
                      onClick={(e) => onPostSpecificPublish(e, post)}
                    >
                      Publish
                    </Button> */}
                    </CardActions>
                  </Card>
                </div>
              );
            })}
        </div>
        <hr />
        <div className="modal-footer pt-0">
          <Button
            type="button"
            // className="btn min btn-primary font-size-12 text-uppercase"
            onClick={onPostSpecificPublish}
          >
            Publish
          </Button>
        </div>
      </>
    );
  }
};

export default ConnectOrganicPost;
