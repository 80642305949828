import React from "react";
import { useLocation } from "react-router-dom";
import "./navbar.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//import Logo from "../../images/logo-light.png";
//import smallLogo from "../../images/logo-sm.png";
// import smallLogo from "../../../public/assets/images/logo-sm.png";
export default function Navbar() {
  const { openSidebar, sideBar, pageInfo } = useProductsContext();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const location = useLocation();

  const pageName = pageInfo.pageName;

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="top_section">
          <div
            className="logo-boxx"
            style={{
              width: sideBar ? "100%" : "80px",
              backgroundColor: "#fff",
            }}
          >
            <Link to="/home">
              <img
                src="./../../assets/images/logo-light.png"
                style={{ display: sideBar ? "block" : "none" }}
                alt="Caucuss"
                className="logo"
              />
            </Link>
            <span
              className="logo-sm"
              style={{ display: sideBar ? "none" : "flex" }}
            >
              <Link to="/home">
                <img
                  src="./../../assets/images/logo-sm.png"
                  alt="CaucusSm"
                  height="45"
                />
              </Link>
            </span>
          </div>

          <div
            style={{ marginLeft: sideBar ? "50px" : "30px", cursor: "pointer" }}
            className="bars"
          >
            <MenuIcon onClick={openSidebar} />
          </div>
        </div>

        <div
          className="topLeft"
          style={{
            background: "white",
            marginLeft: sideBar ? "250px" : isMobile ? "100px" : "400px",
          }}
        >
          <p>{pageName}</p>
        </div>
        {/* <div className="topRight">
          <div className="topbarIconContainer">
            <NotificationsIcon />
            <span className="topIconBadge">2</span>
          </div>

          <img
            src="./../../assets/images/blank_profile.jpg"
            alt=""
            className="topAvatar"
          />
        </div> */}
      </div>
    </div>
  );
}
