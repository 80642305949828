import React, { useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';

import "./styles.css";



export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [isHovering, setIsHovering] = useState(false);

  const onEdgeClick = (evt:any, id:any) => {
    evt.stopPropagation();
    // alert(`remove ${id}`);
    console.log(data.onDeleteEdge(id));
    
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
            zIndex:100
          }}
          className="nodrag nopan"
         
        >
          <div className='widget'>
            <button type='button' className="sai zindexSticky" onClick={(event) => onEdgeClick(event, id)} >
                X
            </button>
            </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
