export const SideMenuList = [
  { label: "ChatBot Tool", menu: "chatbot", icon: "fas fa-robot" },
  {
    label: " Fb Templates",
    menu: "templates",
    icon: "far fa-file-alt",
    connectiontype: ["nav-fb-tab", "nav-fbinsta-tab"],
  },
  {
    label: " Insta Templates",
    menu: "instatemplates",
    icon: "far fa-file-alt",
    connectiontype: ["nav-inst-tab", "nav-fbinsta-tab"],
  },
  {
    label: "Subscribers",
    menu: "subscribers",
    icon: "fab fa-telegram-plane",
    connectiontype: ["nav-fb-tab", "nav-fbinsta-tab"],
  },
  // { label: "Help", menu: "help", icon: "far fa-question-circle" }
];
