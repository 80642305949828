import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { memo } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { useNavigate } from "react-router-dom";
import ToastManager from "../../Components/ToastManager";
interface pubDialogue {
  show: boolean;
  onCancelDialogue: Function;
  onSaveInstaTemplateName: Function;
}
let pageId: string = "";
let pageName: any = null;
let fbAccountId: any = null;
let instaId: any = null;

const btnStyle = { marginTop: 10 };
function UnpauseDialog({
  isPauseOpen,
  onCancelDialogue,
  onSaveInstaTemplateName,
  templateInfo,
  setUnpauseDialog,
  setITemplateList
}: any) {
    const { pubError, pageInfo,savePageIdAndName } = useProductsContext();
    console.log(pageInfo);
    

  const initialValues = {
    checked:
      templateInfo.templateType !== null &&
      templateInfo.templateType !== undefined &&
      templateInfo.templateType == "default"
        ? true
        : false,
  };
  const validationSchema = Yup.object().shape({
    templateName: Yup.string()
      .min(3, "It's too short")
      .required("Please Enter Template Name"),
  });

  const [showDefaultField, setShowDefaultField] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [checkedSpecific, setCheckedSpecific] = useState(false)
  const [checkedDefault, setCheckedDefault] = useState(false)
  const [openSpecific,setOpenSpecific]=useState(true)
  const [templateDetails, setTemplateDetails] = useState({
    templateId: "",
    templateName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (isPauseOpen) {
      setOpenSpecific(true)
      setCheckedDefault(false)
      setCheckedSpecific(false)
    }    
  }, [isPauseOpen]);
  const handleClose = () => {
    onCancelDialogue();
  };

  const onSuccessCallUnpause = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData.statusCode === "Template2000") {
      ToastManager.showToast("Template is Successfully Unpaused", "Success")
    } else if (respData.statusCode === "Template3000") {
      ToastManager.showToast(
        "Error Occured while setting the template to Specific ",
        "Error"
      );
    }
    setUnpauseDialog(false)
    setShowDefaultField(false);
    

  }
  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData) {
      if (respData.statusCode === "Template2000") {
        if (respData.hasDefault) {
          setTemplateId(respData.templateId);
          setTemplateDetails({
            ...templateDetails,
            templateId: respData.templateId,
            templateName: respData.templateName,
          });
          setShowDefaultField(true);
        }
      }
    }
  };

  const onErrorCall = (data: any) => {
      ToastManager.showToast(
        "Error Occured while setting the template to Specific ",
        "Error"
      );
  };
  const onErrorCallDefault = (err: any) => {
    ToastManager.showToast(
      "Error Occured while setting the template to Default ",
      "Error"
    );
  };
  const onErrorCallReset = (err: any) => {
    ToastManager.showToast(
      "Error Occured while setting the template to Specific ",
      "Error"
    );
  };
  
    const onDefaultClick = (event: any) => {
        const fbPageInfo: any = localStorage.getItem("fbPageDetail");
        const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
      const isChecked = event.target.checked;
      if (isChecked) {
          const param = `instaId=${recFbPageInfp.instaId}`;
          
        try { 
        NetworkManager.sendJsonRequest(
          "api/instagram/checkForDefaultTemplate",
          "",
          param,
          "POST",
          true,
          onSuccessCall,
          onErrorCallDefault
        );
        }
        
        catch (error) { }
    } else {
        setShowDefaultField(false);
      setTemplateId("");
      }
      if (isChecked) {
        setCheckedDefault(true);
        setCheckedSpecific(false);
      } else {
        setCheckedDefault(false)
      }
     
  };

  const onSuccessCallResetTemp = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //Do nothing
        ToastManager.showToast(
          "Current template is set from Default to Specific",
          "Success"
        );
      }
    }
  };
  const reSetDefault = (templateRow: any) => {
      
        const fbPageInfo: any = localStorage.getItem("fbPageDetail");
        const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
    const req = 
        `instaId=${recFbPageInfp.instaId}&templateId=${templateId}`
      try { 
      NetworkManager.sendJsonRequest(
        "api/instagram/setCurrentDefaultToSpecific",
        "",
        req,
        "POST",
        true,
        onSuccessCallResetTemp,
        onErrorCallReset
      );
      } catch (error:any) {
        ToastManager.showToast(
          "Error Occured while setting the template to Default ",
          "Error"
        );
    }
  };
  const onSubmit = (values:any,isDefault:any) => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
    
    const req = `templateId=${templateInfo.templateId}&pageId=${recFbPageInfp.pageId
      }&instaId=${recFbPageInfp.instaId}&setTemplateTypeTo=${checkedDefault ? "default" :"specific"
      }&previousDefaultTemplateId=${templateId}`
    
    try {
      NetworkManager.sendJsonRequest(
        "api/instagram/unpauseTemplate",
        "",
        req,
        "POST",
        true,
        onSuccessCallUnpause,
        onErrorCall
      );
    } catch (err: any) { }
  };

  return (
    <div>
      <Dialog
        open={isPauseOpen}
        PaperProps={{
          sx: {
            width: "80%",
            maxHeight: 600,
          },
        }}
      >
        <DialogTitle>Template Selection</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form noValidate>
                {/* <label htmlFor="cardName" style={{ display: "block" }}>
                  Template Name
                </label> */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginTop: "0.5vh",
                    marginBottom: "2vh",
                  }}
                >
                  <Field
                    as={Checkbox}
                    label="Default?"
                    type="checkbox"
                    name="checked"
                    // checked={checkedDefault}
                    onClick={ onDefaultClick}
                  />
                  <InputLabel sx={{ marginTop: "1.5vh" }}>
                    Default Template
                  </InputLabel>
                </Box>

                {showDefaultField ? (
                  <div>
                    <InputLabel sx={{ marginTop: "1.5vh" }}>
                      {`There's already a default Template ${templateDetails.templateName} attached to the page.`}
                    </InputLabel>

                    <InputLabel>
                      Do you want to replace it with the current one?
                    </InputLabel>
                    <Button
                      type="button"
                      sx={{
                        marginBottom: "10px",
                        backgroundColor: "lightblue",
                        borderRadius: "30%",
                      }}
                      onClick={(row: any) => {
                        reSetDefault(row)
                        setOpenSpecific(false)
                        setShowDefaultField(false)
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                ) : (
                  ""
                )}
               
                 <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginTop: "0.5vh",
                    marginBottom: "2vh",
                  }}
                >
                   {openSpecific ?
                  <Field
                    as={Checkbox}
                    // label="Default?"
                    type="checkbox"
                    name="unchecked"
                    checked={checkedSpecific}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setCheckedSpecific(true)
                        setCheckedDefault(false)
                      } else {
                        setCheckedSpecific(false)
                      }
                     
                    }}
                  />:''}
                  <InputLabel sx={{ marginTop: "1.5vh" }}>
                    Specific Template
                  </InputLabel>
                </Box> 
                <Button onClick={({value,isDefault}:any)=>onSubmit(value,isDefault)} style={btnStyle}>
                  Save
                </Button>
                <Button
                  style={btnStyle}
                  onClick={handleClose}
                 disabled={checkedDefault ? true : false}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default memo(UnpauseDialog);

//onClick={onSubmit}
