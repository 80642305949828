import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Menu/Nav";
import SideMenu from "../../Components/Menu/SideMenu";
import { SideMenuList } from "../../utils/constants";
import CreateChatBot from "./CreateChatBot";
import Help from "./Help";
import Templates from "./Templates";
import Box from "@mui/material/Box";
import { SidebarContextPrv } from "../../Contexts/SidebarContext";
import InstaTemplates from "./InstaTemplates";
import SubscribersPage from "./SubscribersPage";
function Dashboard() {
  const { menu }: any = useParams();
  const navigate = useNavigate();
  const [selectedAcc, setSelectedAcc] = useState<any>("selectedConnection");
  useEffect(() => {
    const checkParam = () => {
      const selectedConnection: any =
        localStorage.getItem("selectedConnection");
      const findParam: any = SideMenuList.find(
        (item: any) => item?.menu === menu
      );
      if (findParam === undefined) navigate("/dashboard/chatbot");
    };
    checkParam();
    setSelectedAcc("selectedConnection");
  }, [navigate, menu]);
  return (
    <div id="wrapper" className="left-side-menu-dark">
      <SidebarContextPrv>
        <Navbar />
        <Box sx={{ display: "flex" }}></Box>
        <SideMenu menu={menu} />
        {menu === SideMenuList?.[0].menu ? <CreateChatBot /> : null}
        {menu === SideMenuList?.[1].menu ? <Templates /> : null}
        {menu === SideMenuList?.[2].menu ? <InstaTemplates /> : null}
        {menu === SideMenuList?.[3].menu ? <SubscribersPage /> : null}
        {/* {menu === SideMenuList?.[3].menu ? <Help /> : null} */}
      </SidebarContextPrv>
    </div>
  );
}

export default Dashboard;
