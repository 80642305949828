export const iniEdges = [
  {
    id: "reactflow__edge-0nextStep-1t1",
    source: "0",
    sourceHandle: "nextStep",
    target: "1",
    targetHandle: "t1",
    zIndex: 100,
    style: {
      strokeWidth: 1,
      stroke: "#FF0072",
    },
    markerEnd: {
      color: "#FF0072",
      type: "arrowclosed",
    },
  },
  {
    id: "reactflow__edge-1nextStep-2t1",
    source: "1",
    sourceHandle: "nextStep",
    target: "2",
    targetHandle: "t1",
    zIndex: 100,
    style: {
      strokeWidth: 1,
      stroke: "#FF0072",
    },
    markerEnd: {
      color: "#FF0072",
      type: "arrowclosed",
    },
  },
  {
    id: "reactflow__edge-2nextStep-3t1",
    source: "2",
    sourceHandle: "nextStep",
    target: "3",
    targetHandle: "t1",
    zIndex: 100,
    style: {
      strokeWidth: 1,
      stroke: "#FF0072",
    },
    markerEnd: {
      color: "#FF0072",
      type: "arrowclosed",
    },
  },
  {
    id: "reactflow__edge-3nextStep-1t1",
    source: "3",
    sourceHandle: "nextStep",
    target: "1",
    targetHandle: "t1",
    zIndex: 100,
    style: {
      strokeWidth: 1,
      stroke: "#FF0072",
    },
    markerEnd: {
      color: "#FF0072",
      type: "arrowclosed",
    },
  },
];

export const startNode = [
  {
    id: "0",
    type: "custom",
    data: {
      label: "0",
      textMessage: "Start Node",
      buttonType: "postback",
      websiteURL: "",
    },
    style: {
      background: "#63B3ED",
      color: "white",
      width: 100,
    },
    position: {
      x: 20,
      y: 250,
    },
    width: 100,
    height: 354,
    selected: false,
  },
];
export const initialNodes = [
  {
    id: "0",
    type: "custom",
    data: {
      label: "0",
      textMessage: "Start Node",
      buttonType: "postback",
      websiteURL: "",
    },
    style: {
      background: "#63B3ED",
      color: "white",
      width: 100,
    },
    position: {
      x: 20,
      y: 250,
    },
    width: 100,
    height: 354,
    selected: false,
  },
  {
    id: "1",
    type: "custom",
    data: {
      label: "1",
      cardName: "Card Name 1",
      textMessage: "This is the Message that you want me to display on Node",
      buttonType: "postback",
      websiteURL: "",
      multipleButton: ["One", "two", "Three"],
    },
    style: {
      background: "#63B3ED",
      color: "white",
      width: 100,
    },
    position: {
      x: 365.5814762037137,
      y: 228.51510621081383,
    },
    width: 100,
    height: 354,
    selected: false,
  },
  {
    id: "2",
    type: "custom",
    data: {
      label: "2",
      cardName: "Card Name2",
      textMessage: "This is quick replies .....",
      buttonType: "postback",
      websiteURL: "",
      multipleButton: ["One", "two", "Three"],
    },
    style: {
      background: "#63B3ED",
      color: "white",
      width: 100,
    },
    position: {
      x: 714.748392044238,
      y: 230.49900914172594,
    },
    width: 100,
    height: 354,
    selected: false,
  },
  {
    id: "3",
    type: "custom",
    data: {
      label: "3",
      cardName: "Card Name 3",
      textMessage: "dddd",
      buttonType: "postback",
      websiteURL: "",
      multipleButton: ["One", "two", "Three"],
    },
    style: {
      background: "#63B3ED",
      color: "white",
      width: 100,
    },
    position: {
      x: 1091.6899489175312,
      y: 234.46681500354998,
    },
    width: 100,
    height: 354,
    selected: false,
  },
];
