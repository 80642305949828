import React, { useEffect } from "react";
import "./App.css";
import RoutesComponent from "./Pages/Routes/RoutesComponent";
import BusyLoader from "./Components/BusyLoader";
import $ from "jquery";
import { NetworkManager } from "./Components/NetworkManager";
import { CommonUtil } from "./utils/CommonUtil";
import ToastManager from "./Components/ToastManager";
function App() {
  useEffect(() => {
    getServerPath();
    return () => {};
  }, []);

  let getServerPath = () => {
    $.ajax({
      url: "server.xml?1.2",
      beforeSend: function () {},
      success: function (data: any, statusCode: any, jqXHR: any) {
        var xmlResponse = $(data).find("Server");
        for (var i = 0; i < xmlResponse?.length; i++) {
          var item = xmlResponse[i];
          let routeUrl = $(item).find("URL").text();
          NetworkManager.serverPath = routeUrl;
          let getAppID = $(item).find("APPID").text();
          CommonUtil.appId = getAppID;
        }
      },
      error: function () {},
    });
  };

  return (
    <div className="App">
      {/* <ToastManager /> */}
      <BusyLoader />
      <RoutesComponent />
    </div>
  );
}

export default App;
