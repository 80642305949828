import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import ReactFlow, { Position, Handle } from "reactflow";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FormDialog from "./FormDialog";
import "./styles.css";
import SelIcon from "../../Components/Bot/SelIcon";

interface CustomNodeProps {
  id: String;
  data: any;
}

function CustomNode({ id, data }: CustomNodeProps, { from }: any) {
  const [dilogue, setDialogue] = useState(false);
  const onDelete = (info: any) => {
    data.onDeleteNode(info);
  };
  const onEdit = (info: any) => {
    data.onEdit(info);
  };
  console.log(data);
  let strMsg: string = data.textMessage;
  try {
    if (strMsg.charCodeAt(data.textMessage.indexOf("\n")) === 10) {
      strMsg = data.textMessage.split("\n").join("\\n");
    }
  } catch (e) {}

  let action: any =
    data.supporterCheck === true || data.detractorCheck === true;
  let random: any = data?.randomizer?.length > 0;
  let image: any = "../assets/images/nature1.jpg";

  const panel = useMemo(() => {
    return (
      <>
        <Card
          sx={{
            maxWidth: action || random ? 250 : 345,
            p: action || random ? "" : "5px",
            backgroundColor: action || random ? "white" : "",
          }}
          className={action ? "actions-panel" : "campaign-panel"}
        >
          <Handle
            type="target"
            position={Position.Left}
            id="t1"
            style={{ top: 20 }}
          />
          <div
            className="wrap"
            style={{
              height: action || random ? "60px" : "",
              backgroundColor: action
                ? data.detractorCheck
                  ? "#fd6c33"
                  : "#10ae10"
                : random
                ? "#b0e1ef"
                : "",
            }}
          >
            <div className="main-title">
              <span className="icon">
                {/* <img alt="" src={chatIcon} width={23} height={23} /> */}
                {action || random ? "" : <SelIcon />}
              </span>
              {action ? (
                <div>
                  <h6
                    style={{
                      marginLeft: "16px",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Actions
                  </h6>
                </div>
              ) : random ? (
                <div>
                  <h6
                    style={{
                      marginLeft: "16px",
                      fontSize: "17px",
                      fontWeight: 600,
                    }}
                  >
                    A/B Test
                  </h6>
                </div>
              ) : (
                <span className="text" style={{ paddingLeft: "5px" }}>
                  {data.cardName}
                </span>
              )}
              <div
                style={{
                  position: "absolute",
                  left: "150px",
                  display: `${data.checked === true ? "block" : "none"}`,
                  marginTop: "14px",
                }}
              >
                <AccessTimeFilledIcon />
              </div>
              {data?.textMessage !== "Start Node" ? (
                <>
                  <button
                    type="button"
                    style={{
                      position: "absolute",
                      left: action || random ? "190px" : "250px",
                      top: "10px",
                      borderStyle: "none",
                      // display: `${data.checked === true ? "block" : "none"}`,
                    }}
                    className={
                      action || random
                        ? "btn btn-outline-dark rounded-circle"
                        : "btn btn-outline-danger rounded-circle"
                    }
                    onClick={() => onDelete(data)}
                    // onClick={data.onDeleteNode}
                  >
                    <span style={{ fontWeight: "900", fontSize: "15px" }}>
                      X
                    </span>
                  </button>
                  <button
                    type="button"
                    style={{
                      position: "absolute",
                      left: action || random ? "130px" : "180px",
                      top: "10px",
                      borderStyle: "none",
                      // display: `${data.checked === true ? "block" : "none"}`,
                    }}
                    className="btn btn-outline-secondary rounded-circle"
                    onClick={() => onEdit(data)}
                    // onClick={data.onDeleteNode}
                  >
                    <span
                      style={{
                        fontWeight: "900",
                        fontSize: "15px",
                        borderStyle: "none",
                      }}
                    >
                      <ModeEditIcon />
                    </span>
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <CardContent
            sx={{ p: action || random ? "0px !important" : "16px !important" }}
          >
            <Card
              sx={{
                p: image ? "0px" : "10px",
                display: data.isChecked || action || random ? "none" : "block",
                textAlign:
                  data.textMessage !== "Start Node" ? "left" : "center",
              }}
            >
              {/* <CardMedia
                component="img"
                alt="green iguana"
                height="100"
                image={image}
                sx={{
                  display: data.textMessage !== "Start Node" ? "block" : "none",
                }}
              /> */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ pl: 1, mt: 1.5 }}
              >
                {data.textMessage
                  .split("\n")
                  .map((line: string, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </Typography>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    width: "-webkit-fill-available",
                  }}
                >
                  {data?.multipleButton &&
                    data.multipleButton.map((multData: any, i: any) => {
                      const btns = multData.buttonType;

                      return (
                        <React.Fragment key={i}>
                          {btns !== "QUICK_REPLIES" ? (
                            <Button
                              variant="outlined"
                              size="large"
                              className="rounded-corner-10"
                              key={i}
                            >
                              {multData.multiButton}
                              {btns === "postback" ? (
                                <Handle
                                  type="source"
                                  position={Position.Right}
                                  id={`${multData.id}`}
                                  style={{ bottom: 10 }}
                                />
                              ) : null}
                            </Button>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                </Box>
              </CardActions>
            </Card>
            {/* Optin Card start */}
            <Card
              sx={{
                p: image ? "0px" : "10px",
                display: data.isChecked ? "block" : "none",
                backgroundColor: "#fce6b1",
                textAlign:
                  data.textMessage !== "Start Node" ? "left" : "center",
              }}
            >
              {/* <CardMedia
                component="img"
                alt="green iguana"
                height="100"
                image={image}
                sx={{
                  display: data.textMessage !== "Start Node" ? "block" : "none",
                }}
              /> */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontWeight: 600, pl: 1, mt: 1.5 }}
              >
                {data.optinTitle}
              </Typography>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    width: "-webkit-fill-available",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    className="rounded-corner-10"
                    sx={{ textTransform: "none" }}
                  >
                    {/* <Handle
                      type="source"
                      position={Position.Right}
                       id="nextStep"
                      style={{ bottom: 10 }}
                    /> */}
                    {data.ctatext === "GET_UPDATES"
                      ? "Get updates"
                      : data.ctatext === "ALLOW"
                      ? "Allow messages"
                      : data.ctatext === "GET"
                      ? "Get messages"
                      : data.ctatext === "OPT_IN"
                      ? "Opt in to messages"
                      : data.ctatext === "SIGN_UP"
                      ? "Sign up for messages"
                      : ""}
                  </Button>
                </Box>
              </CardActions>
            </Card>
            {/* Optin Card end */}
            {/* Detractor Card start */}
            <Box
              sx={{
                p: "10px",
                display: action ? "block" : "none",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontWeight: 400,
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {data.detractorCheck ? "Detractor Node" : "Supporter Node"}
              </Typography>
            </Box>
            {/* Detractor Card end */}
            {/* Randomizer Card start */}
            <Card sx={{ display: random ? "block" : "none" }}>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    width: "-webkit-fill-available",
                  }}
                >
                  {data?.randomizer &&
                    data.randomizer.map((variation: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          <Button
                            variant="outlined"
                            size="large"
                            className="rounded-corner-10"
                            sx={{ mt: 0.5, mb: 0.5 }}
                            key={i}
                          >
                            <Handle
                              type="source"
                              position={Position.Right}
                              id={`${variation.id}`}
                              style={{ bottom: 10 }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ mr: 8 }}>
                                {variation.name}
                              </Typography>
                              <Typography sx={{ ml: 8 }}>
                                {Math.floor(variation.percentage)}%
                              </Typography>
                            </Box>
                          </Button>
                        </React.Fragment>
                      );
                    })}
                </Box>
              </CardActions>
            </Card>
            {/* Randomizer Card end */}
          </CardContent>

          <CardActions sx={{ display: action || random ? "none" : "block" }}>
            <Box
              sx={{
                display: "flex",
                rowGap: "5px",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "-webkit-fill-available",
              }}
            >
              {data.multipleButton &&
                data.multipleButton
                  .filter(
                    (multData: any) => multData.buttonType === "QUICK_REPLIES"
                  )
                  .map((multDataQR: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        {multDataQR.buttonType === "QUICK_REPLIES" ? (
                          <Button
                            size="small"
                            className="rounded-corner-10"
                            onClick={data.onClickQuickReply}
                            key={i}
                          >
                            <Handle
                              type="source"
                              position={Position.Right}
                              id={`${multDataQR.id}`}
                              style={{ bottom: 10 }}
                              key={i}
                            />
                            {multDataQR.multiButton}
                          </Button>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
            </Box>
          </CardActions>
          <CardActions>
            <Box
              sx={{
                display: random ? "none" : "flex",
                rowGap: "5px",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "-webkit-fill-available",
              }}
            >
              <Button
                size="small"
                className="rounded-corner-10"
                onClick={data.onAddClick}
              >
                <Handle
                  type="source"
                  position={Position.Right}
                  id="nextStep"
                  style={{ bottom: 10 }}
                />
                Next Step
              </Button>
            </Box>
          </CardActions>
        </Card>

        <FormDialog
          show={dilogue}
          onSaveOrCancelDialogue={() => {}}
          onCancelDialogue={() => {}}
          editNode={{}}
          onUpdateNode={() => {}}
          sourceEdge=""
          deleteButtonLevelEdge={() => {}}
        />
      </>
    );
  }, [data, onDelete, onEdit]);
  return <>{panel}</>;
}

export default memo(CustomNode);
