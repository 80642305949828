import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NetworkManager } from "../../Components/NetworkManager";
import defaultImage from "../../images/defaultImage.png";
import { CommonUtil } from "../../utils/CommonUtil";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import InstagramAccess from "./InstagramAccess";
import FbAndInstaAccess from "./FbAndInstaAccess";
import FacebookAccess from "./FacebookAccess";
interface FacebookManageProps { }

const ExistingFBPages: React.FC<FacebookManageProps> = () => {
  const history = useNavigate();
  const [pagesData, setPagesData] = useState<any[]>([]);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>("nav-fb-tab");

  useEffect(() => {
    localStorage.setItem("selectedConnection", selectedTab);
    localStorage.setItem("activeConnection", selectedTab);
  }, [selectedTab])

  const ontabChange = (e: any) => {
    setSelectedTab(e.target.id);
  };

  // useEffect(() => {
  //   NetworkManager.sendJsonRequest(
  //     "api/account/accessExistingPages",
  //     "",
  //     "",
  //     "GET",
  //     true,
  //     onSuccessCall,
  //     onErrorCall
  //   );
  // }, []);

  // const onSuccessCall = (data: any) => {
  //   var respData = CommonUtil.parseData(data);

  //   if (respData) {
  //     setPagesData(respData);
  //     setPagesCount(respData && respData.length);
  //   }
  // };

  const onErrorCall = (err: any) => { };

  const onConnect = (item: any) => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      const recFbPageInfp = JSON.parse(fbPageInfo);
      if (recFbPageInfp) {
        localStorage.setItem("fbPageDetail", "");
      }
    }
    localStorage.setItem("fbPageDetail", JSON.stringify(item));
    history("/dashboard/templates");
    //  history("/dashboard/templates", { state: { pageId, pageName } });
  };
  return (
    <div className="account-pages container-login100 mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center mb-2">
            <img src="../assets/images/Logo.png" alt="" />
          </div>
        </div>
        <div className="row justify-content-center mb-4 manage">
          <div className="col-md-12 col-lg-8 col-xl-6">
            <div className="manage-page">
              <nav>
                <div
                  className="nav nav-tabs justify-content-center"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    // className="nav-item nav-link active"
                    className={`nav-item nav-link ${selectedTab === "nav-fbinsta-tab" ? "active" : ""
                      }`}
                    id="nav-fbinsta-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    onClick={ontabChange}
                  >
                    {" "}
                    <FacebookIcon color="primary" />
                    Facebook
                    &
                    <InstagramIcon color="secondary" />
                    Insta
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    // className="nav-item nav-link active"
                    className={`nav-item nav-link ${selectedTab === "nav-fb-tab" ? "active" : ""
                      }`}
                    id="nav-fb-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    onClick={ontabChange}
                  >
                    {" "}
                    <FacebookIcon color="primary" />
                    Facebook
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    // className="nav-item nav-link"
                    className={`nav-item nav-link ${selectedTab === "nav-inst-tab" ? "active" : ""
                      }`}
                    id="nav-inst-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    onClick={ontabChange}
                  >
                    <InstagramIcon color="secondary" />
                    Instagram
                  </button>
                </div>
              </nav>{" "}
              {selectedTab === "nav-fb-tab" ? (
                <div id="nav-fb-tab">
                  <FacebookAccess />
                </div>
              ) : ""}
              {selectedTab === 'nav-inst-tab' ? (
                <div id="nav-inst-tab">
                  <InstagramAccess />
                </div>
              ) : ""
              }{""}
              {selectedTab === "nav-fbinsta-tab" ? (
                <div id="nav-fbinsta-tab">
                  <FbAndInstaAccess />
                </div>
              ) : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingFBPages;
