import { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { extractNodesEdges } from "../chatbottool/publishTemplate";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import EnhancedTable from "./TemplateDataTable";

export default function BasicTable() {
  let pageId: string = "";
  let fbPageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;

  const [templateList, setTemplateList] = useState<string[]>([]);
  useEffect(() => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;

    pageId = recFbPageInfp?.fbPageId;
    pageName = recFbPageInfp?.pageName;
    fbAccountId = recFbPageInfp?.fbAccountId;
    if (pageId) {
      console.log(pageId);
      
      let pageDataReq = {
        pageId: pageId,
      };

      try {
        NetworkManager.sendJsonRequest(
          "template/getTemplatesByPageId",
          pageDataReq,
          "",
          "POST",
          true,
          onSuccessCall,
          onErrorCall
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, fbAccountId);
    }
  }, []);

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //setTemplateList(respData.templateDetails);
        const tempalteDetails = respData.templateDetails;
        setTemplateList(tempalteDetails);
      }
    }
  };



  const onErrorCall = (err: any) => {};
  const { sideBar, savePageIdAndName, pageInfo, saveNewNodeAndEdges } =
    useProductsContext();

  const navigate = useNavigate();

  const handleMenu = () => {
    navigate(`/dashboard/chatbot`, { state: { create: true ,from:"fb"} });
  };
 

  return (
    <div
      className="content-page"
      style={{
        marginLeft: sideBar ? "230px" : "120px",
        backgroundColor: "#fff"
      }}
    >
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Caucus</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);"> Fb Templates</a>
                    </li>
                    <li className="breadcrumb-item active">Chatbot Tool</li>
                  </ol>
                </div>
                <h4 className="page-title">Chatbot Tool</h4>
              </div>
            </div>
          </div>
          <div className="sec-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="title">ChatBot Templates List</div>
              <div className="text-end">
            
                <button
                  type="button"
                  onClick={handleMenu}
                  className="btn btn-primary rounded-pill new-chat"
                >
                  <img
                    src="../assets/images/chatbot.svg"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  Fb ChatBot Tool
                </button>
              </div>
            </div>
            <div
              className="tablerounededCorner table-responsive"
              style={{ height: "350px" }}
            >
              <EnhancedTable rows={templateList} title='Fb Templates List' setTemplateList={setTemplateList} />
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
}
