import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { NetworkManager } from "../../Components/NetworkManager";

import defaultImage from "../../images/defaultImage.png";

import { CommonUtil } from "../../utils/CommonUtil";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import InstagramManage from "./InstagramManage";

interface FacebookManageProps { }

const FacebookManage: React.FC<FacebookManageProps> = () => {
  const history = useNavigate();

  const location = useLocation();

  const [pagesData, setPagesData] = useState<any[]>();

  const [pagesCount, setPagesCount] = useState<number>(0);

  const [facebookID, setFacebookID] = useState<any>("");

  const [selectedTab, setSelectedTab] = useState<string>("nav-fb-tab");

  const [acctAndAccessId, setAcctAndAccessId] = useState<any>({});

  const [fbPagesData, setFbPagesData] = useState<any>({});

  const ontabChange = (e: any) => {
    console.log(e.target.id);

    setSelectedTab(e.target.id);
  };

  useEffect(() => {
    if (location.state.tokenObj) {
      let data = {
        accessToken: location.state.tokenObj,
      };

      NetworkManager.sendJsonRequest(
        "api/account/save",
        data,
        "",
        "POST",
        true,
        onSuccessCall,
        onErrorCall
      );
    }
  }, []);

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData) {
      saveAccIdAndAcccessToken(respData);

      setFacebookID(respData.id);

      let pageData = {
        longAccessToken: respData.longAccessToken,
        accountId: respData.id,
      };

      NetworkManager.sendJsonRequest(
        "api/account/pages",
        pageData,
        "",
        "POST",
        true,
        onPageSuccessCall,
        onPageErrorCall
      );
    }
  };

  const onErrorCall = (err: any) => { };

  const onPageSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log();

    if (respData.statusCode === "P0002") {
      let tempArray: any = []
      setPagesData(tempArray);
      saveFbPagesData(respData);

    } else {
      saveFbPagesData(respData);

      setPagesData(respData.pages);
      setPagesCount(respData.pages && respData.pages.length);
    }


  };

  const onPageErrorCall = (err: any) => { };

  const handleConnect = (id: any) => {
    if (id) {
      let connectData = {
        pageAccessToken: id,
        fbAccountId: facebookID,
      };

      NetworkManager.sendJsonRequest(
        "api/account/page/connect",
        connectData,
        "",
        "POST",
        true,
        onConnectSuccessCall,
        onConnectErrorCall
      );
    }
  };

  const onConnectSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData.error) {
      history("/connectError");
    } else if (respData) {
      history("/connectSuccess");
    }
  };

  const onConnectErrorCall = (err: any) => {
    history("/connectError");
  };

  const saveAccIdAndAcccessToken = (data: any) => {
    setAcctAndAccessId(data);
  };

  const saveFbPagesData = (data: any) => {
    setFbPagesData(data);
  };

  const pagestatus = true;

  return (
    <>
      <div className="account-pages container-login100 mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-2">
              <img src="../assets/images/Logo.png" alt="" />
            </div>
          </div>{" "}
          <div className="row justify-content-center mb-4 manage">
            <div className="col-md-12 col-lg-8 col-xl-6">
              <div className="manage-page">
                <nav>
                  <div
                    className="nav nav-tabs justify-content-center"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      // className="nav-item nav-link active"
                      className={`nav-item nav-link ${selectedTab === "nav-fb-tab" ? "active" : ""
                        }`}
                      id="nav-fb-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={ontabChange}
                    >
                      {" "}
                      <FacebookIcon color="primary" />
                      Facebook
                    </button>
                    <button
                      // className="nav-item nav-link"
                      className={`nav-item nav-link ${selectedTab === "nav-inst-tab" ? "active" : ""
                        }`}
                      id="nav-inst-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={ontabChange}
                    >
                      <InstagramIcon color="secondary" />
                      Instagram
                    </button>
                  </div>
                </nav>{" "}
                {selectedTab === "nav-inst-tab" ? (
                  <div className="" id="nav-inst-tab">
                    <InstagramManage
                      acctAndAccessData={acctAndAccessId}
                      fbPagesData={fbPagesData}
                    />
                  </div>
                ) : (
                  <>
                    <div className="text-center">
                      <h3 className="fbtext">

                        {pagesData ?
                          <div>
                            We found {pagesCount ? pagesCount : 0} Facebook Pages managed by you.
                          </div>
                          :
                          <div>
                            Fetching Facebook Pages
                          </div>
                        }
                      </h3>
                    </div>
                    {pagesData && pagesCount > 0
                      ? pagesData.map((item, index) => {
                        const pagestatus = item?.pageStatus === "CONNECTED";
                        return (
                          <div className="card" key={index}>
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widgets_div d-flex align-items-center">
                                  <div className="icon_div">
                                    <span>
                                      {item.picture ? (
                                        <img
                                          src={item.picture}
                                          className="rounded-circle avatar-sm"
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={defaultImage}
                                          alt=""
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>{" "}
                                  <div className="av-div">
                                    {item.pageName}
                                  </div>
                                </div>{" "}
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className={`btn rounded-pill btn-small btn-sm ${pagestatus
                                      ? "btn-secondary"
                                      : "btn-primary"
                                      }`}
                                    onClick={
                                      !pagestatus
                                        ? () =>
                                          handleConnect(
                                            item.pageAccessToken
                                          )
                                        : () => { }
                                    }
                                  >
                                    {pagestatus ? "Connected" : "Connect"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </>
                )}{" "}
                {/* <div className="text-center">
                  <h3 className="main-title">
                    We found {pagesCount} Facebook Pages managed by you.
                  </h3>
                </div> */}{" "}
                {/* {pagesData && pagesCount > 0
                  ? pagesData.map((item, index) => {
                      const pagestatus = item?.pageStatus === "CONNECTED";              return (
                        <div className="card" key={index}>
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="widgets_div d-flex align-items-center">
                                <div className="icon_div">
                                  <span>
                                    {item.picture ? (
                                      <img
                                        src={item.picture}
                                        className="rounded-circle avatar-sm"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={defaultImage}
                                        alt=""
                                        style={{
                                          width: "50px",                                  height: "50px",                                  borderRadius: "50%",
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>                        <div className="av-div">{item.pageName}</div>
                              </div>                      <div className="text-right">
                                <button
                                  type="button"
                                  className={`btn rounded-pill btn-small btn-sm ${
                                    pagestatus ? "btn-secondary" : "btn-primary"
                                  }`}
                                  onClick={
                                    !pagestatus
                                      ? () => handleConnect("hii")
                                      : () => {}
                                  }
                                >
                                  {pagestatus ? "Connected" : "Connect"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookManage;
