import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConnectCustomPost from "./ConnectCustomPost";
import ConnectOrganicPost from "./ConnectOrganicPost";
import ConnectAdPost from "./ConnectAdPost";
import { useProductsContext } from "../../Contexts/SidebarContext";
import ConnectOrganicInstaPost from "./ConnectOrganicInstaPosts";
const PostConnectDialog = ({ isOpen, onCancelDialogue, templateInfo }: any) => {
  const [value, setValue] = useState("custom");
  const [open, setOpen] = useState(false);
  const selectedConnection: any = localStorage.getItem("selectedConnection");

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleClose = () => {
    //setValue("custom")
    onCancelDialogue();
  };
  const { pageInfo } = useProductsContext();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        PaperProps={{
          sx: {
            width: "60%",
            height: "90%",
            maxWidth: "1000px",
            //maxHeight: "90px",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Select a Post Below or find it by URL or ID
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
        // sx={{
        //   width: "100%",
        //   bgcolor: "background.paper",
        //   marginLeft: "180px",
        // }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="Post Connection"
                textColor="primary"
                indicatorColor="primary"
                centered
              >
                <Tab
                  //  icon={<FavoriteIcon />}
                  iconPosition="start"
                  label="Custom Post"
                  value="custom"
                />
                <Tab label="Organic" value="organic" />
                <Tab label="Ad Post" value="ad" />
              </TabList>
            </Box>

            <TabPanel value="custom">
              <ConnectCustomPost />
            </TabPanel>
            {selectedConnection === "nav-fb-tab" ?
              <TabPanel value="organic">
                {" "}
                <ConnectOrganicPost
                  templateName={templateInfo.templateName}
                  handleClose={handleClose}
                />
              </TabPanel> :
              <TabPanel value="organic">
                {" "}
                <ConnectOrganicInstaPost
                  templateName={templateInfo.templateName}
                  handleClose={handleClose}
                />
              </TabPanel>
            }
            <TabPanel value="ad">
              {" "}
              <ConnectAdPost />
            </TabPanel>
          </TabContext>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default PostConnectDialog;
