import React, { useState, useEffect } from "react";
import NewBot from "../../Components/Bot/NewBot";
import ChatBotTool from "../chatbottool";
import { useLocation } from "react-router-dom";
import { useProductsContext } from "../../Contexts/SidebarContext";
function CreateChatBot() {
  const { saveNewNodeAndEdges, saveTemplateName } = useProductsContext();
  const location = useLocation();
  const [create, setCreate] = useState<boolean>(true);
  const selectedConnection: any = localStorage.getItem("selectedConnection");
  useEffect(() => {
   
    if (location?.state != null && location?.state?.create !== true) {
      setCreate(false);
    }
  }, [location?.state]);

  const onNewBotClick = () => {
    setCreate(false);
    saveNewNodeAndEdges([], []);
    saveTemplateName({});
  };
  
  return selectedConnection && create ? <NewBot onNewBotClick={onNewBotClick} from={location?.state?.from} /> : <ChatBotTool />
  // return <>{location?.state?.from}</>
}

export default CreateChatBot;
