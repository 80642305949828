import React from "react";
import "./styles.css";
import ReactFlowTemplate from "./ReactFlowTemplate";
import { ReactFlowProvider } from "reactflow";
import { useProductsContext } from "../../Contexts/SidebarContext";
interface ChatBotToolProps {}

const ChatBotTool: React.FC<ChatBotToolProps> = () => {
  //const history = useNavigate();
  const { sideBar } = useProductsContext();

  return (
    <>
      <div
        className="chatBotRoot"
        style={{ marginLeft: sideBar ? "210px" : "70px" }}
      >
        <ReactFlowProvider>
          <ReactFlowTemplate />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default ChatBotTool;
