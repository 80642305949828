import React from "react";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login";

interface ConnectProps {}

const Connect: React.FC<ConnectProps> = () => {
  const history = useNavigate();
  const responseFacebook = (response: any) => {
    if (response && response.accessToken) {
      let tokenObj = response.accessToken;
      history("/facebookManage", {
        state: {
          tokenObj,
        },
      });
      localStorage.setItem("accessToken", response.accessToken);
    }
  };
  return (
    <div className="account-pages container-login100">
      <div className="container">
        <Header />

        <div className="row justify-content-center mb-4">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card fb-title">
              <div className="card-body text-center">
                <div className="card-block">
                  <h1 className="card-title mt-3 mb-0">
                    Connect Caucus to your Facebook Page
                  </h1>

                  {/* <div className="fb-button mt-4">
                                            <a href="thank-you.html" className="facebook btn d-flex rounded-pill justify-content-center align-items-center">
                                                <div className="fb-rounded-circle mr-2"><span className="fab fa-facebook-f"></span></div> Continue with Facebook
                                            </a>
                                        </div> */}
                  <div className="fb-button mt-4">
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="email, pages_show_list, pages_manage_metadata, pages_read_user_content, pages_read_engagement, pages_messaging,ads_management,instagram_basic,instagram_manage_comments,instagram_manage_messages,business_management"
                      //scope="email,pages_user_locale,pages_user_gender,pages_user_timezone,pages_messaging,pages_read_user_content,pages_read_engagement,pages_show_list,pages_manage_metadata"
                      callback={responseFacebook}
                      icon="fa-facebook"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect;
