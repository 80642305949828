import { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { extractNodesEdges } from "../chatbottool/publishTemplate";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import InstaTempTableData from "./InstaTempTableData";

export default function BasicTable() {
  let pageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;
  let instaId: any = null;
  let instaPageId: string = "";
  const [iTemplateList, setITemplateList] = useState<string[]>([]);

  // ------------- Instagram -----------

  useEffect(() => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;

    pageId = recFbPageInfp?.pageId;
    pageName = recFbPageInfp?.instaUserName;
    instaId = recFbPageInfp?.instaId;
    let params: any = "";
    if (pageId) {
      params = `pageId=${pageId}`;
      try {
        NetworkManager.sendJsonRequest(
          "api/instagram/getTemplatesOfInstaId",
          "",
          params,
          "GET",
          true,
          onSuccessCallInsta,
          onErrorCall
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, null, instaId);
    }
  }, []);

  const onSuccessCallInsta = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      // if (respData.statusCode === "Template2000") {
      //setTemplateList(respData.templateDetails);
      const iTemplateDetails = respData;
      setITemplateList(iTemplateDetails);
      // }
    }
  };

  const onErrorCall = (err: any) => {};
  const { sideBar, savePageIdAndName, pageInfo, saveNewNodeAndEdges } =
    useProductsContext();

  const navigate = useNavigate();

  const handleMenu = () => {
    navigate(`/dashboard/chatbot`, { state: { create: true, from: "fb" } });
  };
  const handleMenuInsta = () => {
    navigate(`/dashboard/chatbot`, { state: { create: true, from: "insta" } });
  };

  return (
    <div
      className="content-page"
      style={{
        marginLeft: sideBar ? "230px" : "120px",
        backgroundColor: "#fff",
      }}
    >
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Caucus</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);"> Insta Templates</a>
                    </li>
                    <li className="breadcrumb-item active">Chatbot Tool</li>
                  </ol>
                </div>
                <h4 className="page-title">Chatbot Tool</h4>
              </div>
            </div>
          </div>
          <div className="sec-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="title">ChatBot Templates List</div>
              <div className="text-end">
                <button
                  type="button"
                  onClick={handleMenuInsta}
                  className="btn btn-primary rounded-pill new-chat"
                >
                  <img
                    src="../assets/images/chatbot.svg"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  Insta ChatBot Tool
                </button>
              </div>
            </div>
            <div
              className="tablerounededCorner table-responsive"
              style={{ height: "350px" }}
            >
              <InstaTempTableData
                rows={iTemplateList}
                title="Insta Templates List"
                setITemplateList={setITemplateList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
