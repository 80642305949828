import BusyLoader from "./BusyLoader";
import { CommonUtil } from "../utils/CommonUtil";
import ToastManager from "./ToastManager";

export class NetworkManager {
  //static serverPath:string = "http://54.184.126.249:8080/";
  //static serverPath:string = "http://10.10.10.219:8080/";

  static serverPath: string = "http://52.42.49.205:8080/"; //https://api.joincaucus.com/ http://52.42.49.205:8080/
  static address: string = "";
  static jsonData: any = "";
  static qParam: string = "";
  static method: string = "";
  //static busy:boolean = "";
  static successCallBack: any = "";
  static errorCallBack: any = "";

  static sendJsonRequest(
    address: string,
    jsonData: any,
    qParam: string,
    method: string,
    busy: boolean,
    successCallBack: any,
    errorCallBack: any
  ) {
    /*this.address = address;
   this.jsonData = jsonData;
   this.qParam = qParam;
   this.method = method;
   this.busy = busy;
   this.successCallBack = successCallBack;
    this.errorCallBack = errorCallBack;*/

    var serverUrl = this.serverPath + address;
    var requestOptions: object = {};
    if (qParam) {
      serverUrl = serverUrl + "?" + qParam;
    }
    if (busy) {
      BusyLoader.showLoader();
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (address !== "cognito/login" && address !== "cognito/mfa") {
      if (localStorage.getItem("cognitoAccessToken")) {
        var token = "Bearer " + localStorage.getItem("cognitoAccessToken");
        myHeaders.append("Authorization", token); //"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmbjJsbjJAeWFob28uY29tIiwibG9naW5JZCI6ImZuMmxuMkB5YWhvby5jb20iLCJzYWFzT3JnS2V5IjpudWxsLCJpc3MiOiJ2SHViQXBwIiwib3JnS2V5IjoxMjUsImV4cCI6MTY1OTcwNDQxMywiaWF0IjoxNjU5NjE4MDEzLCJ1c2VyS2V5IjoyMX0.fhkqNjflZnZMzAFGh6kS99fGgRkmdj-5B02jLEMsBYV-aRuIWu04x2NEWY5Qzi_Xcq0plidl4y2sWqagr1ZCHA");
      }
    }

    if (jsonData !== "") {
      var rawData = JSON.stringify(jsonData);
      requestOptions = { method: method, headers: myHeaders, body: rawData };
    } else {
      requestOptions = { method: method, headers: myHeaders };
    }

    fetch(serverUrl, requestOptions)
      .then((response) => {
        //
        //status = response.status;

        if (response.ok) {
          return response.text();

          // return restObj;
        } else {
          //   // restObj.status = "fail";
          //   restObj.statusCode = response.status
          //   restObj.data = response.text()
          //   return restObj
          //   //errorCallBack(response.statusText);

          return response.json();
        }

        //return restObj;
      })
      .then((result) => {
        //
        //result.status = "Success";
        if (busy) {
          BusyLoader.hideLoader();
        }
        if (typeof result === "string") {
          successCallBack(result);
        } else if (typeof result === "object") {
          let response: any = result;
          if (response) {
            if (response.errorCode === "TOKEN_EXPIRED") {
              ToastManager.showToast(
                "Refresh Token Expired, Please Login again.",
                "Error"
              );
              // let refreshTokenAPI: string = "api/auth/refreshToken"; // + qParams;
              // let req: any = {};
              // req.refreshToken = localStorage.getItem("refreshToken");
            } else if (response.errorMessage === "EXPIRED_REFRESH_TOKEN") {
              // Confirm.show("Info", "Refresh Token Expired. Please login again.", [{label: 'OK', callback: function () {
              //        CommonUtil.onLogout();
              //   },
              // }])
            } else {
              successCallBack(JSON.stringify(result));
            }
          } else {
            successCallBack(JSON.stringify(result));
          }
        }
      })
      .catch((err) => {
        if (busy) {
          BusyLoader.hideLoader();
        }
        errorCallBack(err);
      });
  }
}
