import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  CardActions,
  Switch,
  Box,
  Link,
  Button,
} from "@mui/material";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../NetworkManager";
import ToastManager from "../../Components/ToastManager";
const SpecificPostInfo = ({ templateInfo, handleClose }: any) => {
  const { pageInfo } = useProductsContext();

  const [postsAsso, setPostsAsso] = useState([]);
  useEffect(() => {
    let data: any = {};
    let pageId = pageInfo?.pageId;
    if (pageId) {
      data = {
        templateId: templateInfo.templateId,
        templateName: templateInfo.templateName,
        pageId: pageId,
      };

      try {
        NetworkManager.sendJsonRequest(
          "getTiedPosts",
          data,
          "",
          "POST",
          true,
          onSuccessCall,
          onErrorCall
        );
      } catch (error) {}
    }
  }, []);
  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData && respData.statusCode === "Template2000") {
      setPostsAsso(respData.postAssociationDetails);
    }
  };
  const onSuccessCall2 = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      handleClose("specific");
      //setPosts(respData);
    }
  };

  const onErrorCall = (err: any) => {};
  const onSwitchActiveOrInactive = (e: any, post: any) => {
    // setSwitchChecked(!switchChecked);
    let data: any = {};
    let pageId = pageInfo?.pageId;
    if (pageId) {
      data = {
        pageId: pageId,
        templateName: templateInfo.templateName,
        postId: post.postId,
        templateId: post.templateId,
        postTemplateStatus: "hello",
      };
    }
  };
  {
    return (
      <>
        {postsAsso?.length > 0 &&
          postsAsso.map((post: any, indx: any) => {
            const idPost = post.postId.split("_")[1];
            return (
              <div key={indx} style={{ margin: "10px" }}>
                <Card sx={{ maxWidth: 345, margin: "auto" }}>
                  <CardActionArea>
                    {post?.postDetails?.mediaURL !== "" ? (
                      <CardMedia
                        component="img"
                        height="140"
                        image={post.postDetails.mediaURL}
                        alt="post media"
                      />
                    ) : null}
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {post?.postDetails?.postMessage != null
                          ? post.postDetails.postMessage
                          : "Sorry, No Post Message"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <SwitchItem
                    post={post}
                    pageInfo={pageInfo}
                    templateInfo={templateInfo}
                  />
                </Card>
              </div>
            );
          })}
      </>
    );
  }
};
export const SwitchItem = ({ post, pageInfo, templateInfo }: any) => {
  const checkActive = post.postTemplateStatus === "active" ? true : false;
  const [statusState, setStatusState] = useState(checkActive);

  const onSuccessCallStatus = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData && respData.statusCode === "Template2000") {
      //Do nothing
      ToastManager.showToast(
        "Template status for the post is changed ",
        "Success"
      );
    } else {
      setStatusState((prevCheck) => !prevCheck);
      ToastManager.showToast(
        "Error occured while changing the status ",
        "Error"
      );
    }
  };

  const onErrorCall = (err: any) => {
    setStatusState((prevCheck) => !prevCheck);
    ToastManager.showToast("Error occured while changing the status ", "Error");
  };
  const onSwitchActiveOrInactive = (e: any, post: any) => {
    setStatusState((prevCheck) => !prevCheck);
    let data: any = {};
    let pageId = pageInfo?.pageId;
    if (pageId) {
      data = {
        pageId: pageId,
        templateName: templateInfo.templateName,
        postId: post.postId,
        templateId: templateInfo.templateId,
        postTemplateStatus: `${statusState ? "inactive" : "active"}`,
      };

      try {
        NetworkManager.sendJsonRequest(
          "updatePostTemplateStatus",
          data,
          "",
          "POST",
          true,
          onSuccessCallStatus,
          onErrorCall
        );
      } catch (error) {}
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
        justifyContent: "space-between",
      }}
    >
      <CardActions>
        <Switch
          onChange={(e) => onSwitchActiveOrInactive(e, post)}
          value={statusState}
          checked={!!statusState}
        />
      </CardActions>
      <CardActions disableSpacing>
        <Button href={post.postDetails.fbPostURL}>View the Post</Button>
      </CardActions>
    </Box>
  );
};
export default SpecificPostInfo;
